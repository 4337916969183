<template>
    <div class="row pricing-table-one justify-content-center" >
        <div class="col-md-6 col-xl-4 pricing-table wow move-up" v-for="(pricing, index) in pricingTable" :key="index" :class="{'pricing-table--popular__bg': pricing.active}">
            <div class="pricing-table__inner" :class="{'bg-gradient': pricing.active}">
                <div class="pricing-table__feature-mark" v-if="pricing.isPopular">
                    <span>Popular</span>
                </div>
                <div class="pricing-table__header">
                    <div class="pricing-table__image">
                        <img :src="pricing.thumb" class="img-fluid" :alt="pricing.alt">
                    </div>
                    <h5 class="pricing-table__title">{{ pricing.title }}</h5>
                    <div class="pricing-table__price-wrap"  style="margin-bottom:15px">
                        <h6  class="currency">$</h6>
                        <h6 v-if="pricing.title!='Corporativo'" class="price" :style="stylePrice(pricing.title)">{{ pricing.value }}</h6>
                        <h4 v-if="pricing.title=='Corporativo'" class="pricing-table__title" :style="stylePrice(pricing.title)"> {{ pricing.value }}</h4>
                        <h6 v-if="pricing.title!='Corporativo'" class="currency">00</h6>
                    </div>
                </div>
                <div class="pricing-table__body">
                    <ul class="pricing-table__list text-left">
                        <li v-for="(list, index) in pricing.price" :key="index">{{ list }}</li>
                    </ul>
                </div>
                <div v-if="pricing.title=='Estándar'"><br><br></div>
                <div class="pricing-table__footer">
                    <a @click="isShowModal=true" class="ht-btn ht-btn--outline ht-btn-default" :class="{'btn--white': pricing.active}">Obtener</a>
                </div>
                
            </div>

        </div>
        <!------------- MODAL ------------------>
        <b-modal   v-model="isShowModal" size="md" hide-footer>
            <Registro></Registro>
        </b-modal>
    </div>
</template>

<script>
    import Vue from 'vue'
    import Vuelidate from 'vuelidate'
    import { minLength, maxLength, required, email } from 'vuelidate/lib/validators';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import Registro from '../components/Registro'

    Vue.use(Vuelidate)
    Vue.component('vue-phone-number-input', VuePhoneNumberInput);
    export default {
        name: 'PricingOne',
        components: {
            VuePhoneNumberInput,
            Registro
        },
        data (){
            return {
                pricingTable: [
                    {
                        thumb: require('../assets/img/pricing/aeroland-branding-pricing-image-01.png'),
                        title: 'Sin Costo',
                        value: '0',
                        duration: 'anual',
                        alt: 'Pricing Thumb',
                        isPopular: false,
                        active: false,
                        price: ['1 Usuario',  '500 MB en la nube', 'Recepción de pagos en línea', 'Firma Electrónica simple', 'Soporte técnico']
                    },
                    {
                        thumb: require('../assets/img/pricing/aeroland-branding-pricing-image-03.png'),
                        title: 'Corporativo',
                        value: 'Personalizado',
                        duration: 'anual',
                        alt: 'Pricing Thumb',
                        isPopular: true,
                        active: true,
                        price: ['Usuarios ilimitados','Servidor propio','Recepción de pagos en línea','Facturación en línea para clientes','Firma electrónica simple y avanzada', 'Soporte técnico']
                    }
                ],
                isShowModal:false,
                contacto:{
                    nombre:"",
                    tipoOrg:"",
                    correo:"",
                    telefono:""
                },
                optionsSector:[
                    "Gobierno",
                    "Educación",
                    "Salud",
                    "Retail",
                    "Tecnología",
                    "Religión",
                    "Organización Civil",
                    "Servicios",
                    "Otro"
                ],
                resultsPais:null
            }
        },
        methods:{
            stylePrice(title){
                if(title == ''){
                    return ''//'text-decoration:line-through red;  '
                }
                return '';
            }
        },
        validations: {
        contacto:{
                nombre:{
                    minLength: minLength(5),
                    maxLength: maxLength(50),
                    required                    
                },
                tipoOrg:{
                    required
                },
                correo:{
                    required,
                    email,
                    maxLength: maxLength(50)
                }
            }
        }
    }
</script>