<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="testimonial-slider">
                <div class="swiper-container testimonial-slider__container">
                    <div class="swiper-wrapper testimonial-slider__wrapper">
                        <swiper :options="swiperOption">
                            <div class="swiper-slide">
                                <div class="service-grid text-center">
                                    <div class="ht-service-box--one style-03">
                                        <router-link :to="''">
                                            <div class="image__media" style="display: block; margin-left: auto; margin-right: auto;">
                                                <img src="../assets/img/icons/aeroland-digital-store-sketch-icon.png" class="img-fluid" alt="aeroland-app-showcase-box-icon-image-01">
                                            </div>
                                            <div class="content">
                                                <h6 >Disponibilidad</h6>
                                                <span class="designation">Servicio 24/7</span><br><br>
                                                <div class="service_text">Evita filas y largos tiempos de espera en tus oficinas y permite a tus clientes realizar trámites y pagos desde la plataforma en cualquier lugar sin restricción de horarios.</div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="service-grid text-center">
                                    <div class="ht-service-box--one style-03">
                                        <router-link :to="''">
                                            <div class="image__media" style="display: block; margin-left: auto; margin-right: auto;">
                                                <img src="../assets/img/icons/aeroland-box-image-04.png" class="img-fluid" alt="aeroland-app-showcase-box-icon-image-01">
                                            </div>
                                            <div class="content">
                                                <h6 >Productividad</h6>
                                                <span class="designation">Ahorra tiempo y dinero</span><br><br>
                                                <div class="service_text">Reduce costos operativos al migrar el flujo de trabajo de tus áreas administrativas a nuestra plataforma 100% en línea y accede  desde cualquier dispositivo.</div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="service-grid text-center">
                                    <div class="ht-service-box--one style-03">
                                        <router-link :to="''">
                                            <div class="image__media" style="display: block; margin-left: auto; margin-right: auto;">
                                                <img src="../assets/img/icons/aeroland-box-image-02.png" class="img-fluid" alt="aeroland-app-showcase-box-icon-image-01">
                                            </div>
                                            <div class="content">
                                                <h6 >Legalidad</h6>
                                                <span class="designation">Certeza jurídica</span><br><br>
                                                <div class="service_text">Brinda procesos ágiles y transparentes al incluir la firma electrónica avanzada en la emisión de documentos dando plena seguridad en cada operación realizada.</div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </swiper>
                    </div>
                </div>
                <div class="swiper-pagination swiper-pagination-1 section-space--mt_60"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TestimonialTwo',
        data () {
            return {
                swiperOption: {
                    speed: 800,
                    loop: true,
                    slidesPerView: 3,
                    spaceBetween : 30,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    breakpoints: {
                        1499:{
                            slidesPerView : 3
                        },
                        
                        991:{
                            slidesPerView : 2
                        },

                        767:{
                            slidesPerView : 1

                        }
                    }
                }
            }
        }   
    }
</script>
