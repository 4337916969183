<template>
    <div class="ht-tab-wrap">
        <b-tabs class="ht-tab-menu wow move-up">
            <Ventaja2 />
        </b-tabs>
    </div>
</template>

<script>
    import Ventaja2 from '../components/Ventaja2'

    export default {
        computed: {
            
        },
        data () {
            return {
             
            }
        },
        name: 'TabOne',
        components: {
            Ventaja2
        }
    };
</script>