<template>
    <div  class="bg-cover bg-img">
        <section class="hero-branding "  id="home">
            <div class="container-fluid container-fluid--cp-150">
                <div class="row">
                    <div class="col-12 ">
                        <div class="hero-content">
                            <h3 class="h3 hero-content-title text-white">Ahora ya puedes ofrecer</h3>
                            <h1 class="h1 hero-content-title text-white">Trámites y Pagos En Línea.</h1>
                        </div>
                    </div>   
                </div>
                <div class="row">
                    <div class="col-9 col-md-6">
                        <div >
                            <h6 class="hero-content-subtitle text-white mt-20">GobiNet es la plataforma digital que ayuda gobiernos municipales a digitalizar la prestación de trámites y servicios de forma rápida, fácil y segura.</h6>
                            <div class="slider-button mt-30">
                                <b-button class="btn btn--green scroll" @click="isShowModal=true">Obtener cuenta gratis</b-button>
                            </div>
                        </div>
                    </div>   
                </div>            
            </div>    
        </section>
    <div >
        <!-- efecto del encabezado -->
        <CurveShape  id="ventajas">
            <template v-slot:bgcolor>
                <path style="fill: #f6f5f9;
                    stroke-miterlimit:10;" 
                    d="M 3360.5 97.739 c -242 0 -480 -48.375 -480 -48.375 S 2647.5 0.5 2400.5 0.5 s -480 48.375 -480 48.375 s -238 48.864 -480 48.864 s -480 -48.375 -480 -48.375 S 727.5 0.5 480.5 0.5 S 0.5 48.875 0.5 48.875 V 173 h 1920 h 1920 V 48.875 C 3840.5 48.875 3602.5 97.739 3360.5 97.739 z"/>
            </template>
        </CurveShape>    
    </div>
    <!------------- MODAL ------------------>
        <b-modal   v-model="isShowModal" size="md" hide-footer>
            <Registro></Registro>
        </b-modal>
    </div>
</template>

<script>
    import CurveShape from '../components/CurveShape'
    import Registro from '../components/Registro'
    export default {
        data (){
            return {
                bgIm: {
                    backgroundImage: `url(${require('../assets/img/hero/app-landing/landing_gobinet-01.jpg')})`
                },
                isShowModal:false
            }
        },
        computed:{
            bgI : function () {
                return {
                    backgroundImage: `url(${require('../assets/img/hero/app-landing/landing_gobinet-01.jpg')})`
                }
            }
        },
        components:{
            CurveShape,
            Registro
        }
    }
</script>

<style lang="scss" scoped>
    @import '../assets/scss/variabls.scss';
    @import '../assets/scss/elements/hero-branding.scss';
    .hero-branding {
        // responsive
        @media only screen and (min-width: 992px){
            height: 700px;
            padding-right: 150px;
        }
        @media #{$tablet-device}{
            height: 650px;
            padding-right: 0px;
        }
        @media #{$large-mobile}{
            height: 600px;
            padding-right: 0px;
        }
        @media #{$small-mobile}{
            height: 550px;
            padding-right: 0px;
        }
        @media #{$extra-small-mobile}{
            height: 500px;
            padding-right: 0px;
        }
    }
    .hero-content {
        @media #{$large-mobile}{
            width: 100%;
        }
    }
    .bg-img{
        @media only screen and (min-width: 992px) and (max-width: 2040px){
            background-image: url('../assets/img/hero/app-landing/landing_gobinet-01.jpg');
        }
        @media only screen and (min-width: 0px) and (max-width: 992px){
            background-image: url('../assets/img/hero/app-landing/landing_gobinet-02.jpg');
        }
    }
</style>
