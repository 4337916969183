<template>
    <div class="row mtn-30">
        <intersect @enter="isShowFact1=true" @leave="isShowFact1=false">
            <div class="col-lg-4 col-md-6 col-sm-6 wow move-up" style="min-height:250px;">
                <div class="fun-fact--one mt-30 text-center" v-if="isShowFact1">
                    <h5 class="fun-fact__title">Operaciones<br> Procesadas</h5>
                    <div class="fun-fact__count counter">
                        + <ICountUp :endVal="7000000"/>
                    </div>
                    <span class="fun-fact__text">DESDE EL 2016</span>
                </div>
            </div>
        </intersect>
        <intersect @enter="isShowFact2=true" @leave="isShowFact2=false">
            <div class="col-lg-4 col-md-6 col-sm-6 wow move-up" style="min-height:250px;">
                <div class="fun-fact--one mt-30 text-center" v-if="isShowFact2">
                    <h5 class="fun-fact__title">Facturas <br> Emitidas</h5>
                    <div class="fun-fact__count counter">
                        + <ICountUp :endVal="9000000"/>
                    </div>
                    <span class="fun-fact__text">DESDE EL 2015</span>
                </div>
            </div>
        </intersect>
        <intersect @enter="isShowFact3=true" @leave="isShowFact3=false">
            <div class="col-lg-4 col-md-6 col-sm-6 wow move-up" style="min-height:250px;">
                <div class="fun-fact--one mt-30 text-center" v-if="isShowFact3">
                    <h5 class="fun-fact__title">Horas<br>En Funcionamiento</h5>
                    <div class="fun-fact__count counter">
                        + <ICountUp :endVal="60000"/>
                    </div>
                    <span class="fun-fact__text">SIN INTERRUPCIONES</span>
                </div>
            </div>
        </intersect>
    </div>
</template>

<script>
    import Intersect from 'vue-intersect'
    import ICountUp from 'vue-countup-v2';
    export default {
        name: 'FunFactOne',
        components: {
            ICountUp,
            Intersect
        },
        data() {
            return {
                delay: 300,
                isShowFact1:false,
                isShowFact2:false,
                isShowFact3:false
            };
        }
    };
</script>