<template>
    <div class="ht-tab-wrap" >
        <b-row align-h="center"  >
            <b-col cols="12" md="6">
                <b-embed
                    style="border-radius: 45px; "
                    type="iframe"
                    aspect="16by9"
                    src="https://www.youtube.com/embed/OzNLA3lw-sI?rel=0"
                    allowfullscreen>
                </b-embed>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import Ventaja2 from '../components/Ventaja2'

    export default {
        computed: {
            marginVentaja2() {
                if(this.width > 650){
                    return "margin-top:200px;";
                }else{
                    return "margin-top:50px;";
                }
            }
        },
        data () {
            return {
                width:window.innerWidth
            }
        },
        name: 'TabOne',
        components: {
            Ventaja2
        }
    };
</script>