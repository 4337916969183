<template>
    <div>
        <b-carousel
            id="carousel-fade"
            style="text-shadow: 0px 0px 2px #000; margin-left: -16px; margin-right: -16px; margin-top: -16px;"
            fade
            indicators
            img-width="1050"
            img-height="480"
        >
            <b-carousel-slide
            
            :img-src="require('../assets/img/banners/registro.png')"
            ></b-carousel-slide>
            <!--
            <b-carousel-slide
            caption="Second Slide"
            img-src="https://picsum.photos/1024/480/?image=12"
            ></b-carousel-slide>
            <b-carousel-slide
            caption="Third Slide"
            img-src="https://picsum.photos/1024/480/?image=22" --!
            ></b-carousel-slide>-->
        </b-carousel>
        <div style=" margin-bottom: 30px;  margin-top: 30px">
            <div v-if="!registrado">
                <b-row align-h="center" v-if="!isSendingData">
                    <b-col cols="11" md="9">
                        <label>Nombre y Apellido:</label>
                        <b-form-input  :state="!$v.contacto.nombre.$invalid" type="text" v-model.trim="contacto.nombre"></b-form-input>
                        <div style="color:red" v-if="!$v.contacto.nombre.minLength">Ingresa tu nombre y apellido.</div>
                        <div style="color:red" v-if="!$v.contacto.nombre.maxLength">El nombre es muy largo.</div>
                        <div style="color:red" v-if="!$v.contacto.nombre.required">Este campo es obligatorio.</div>
                    </b-col>
                </b-row>
                <b-row align-h="center" v-if="!isSendingData">
                    <b-col cols="11" md="9">
                        <br>
                        <label>Sector u Organización:</label>
                        <b-form-select :state="!$v.contacto.tipoOrg.$invalid" :options="optionsSector"  v-model.trim="contacto.tipoOrg"></b-form-select>
                        <div style="color:red" v-if="!$v.contacto.tipoOrg.required">Este campo es obligatorio.</div>
                    </b-col><br>
                </b-row>
                <b-row align-h="center" v-if="!isSendingData">
                    <b-col cols="11" md="9">
                        <br>
                        <label>Correo Electónico:</label>
                        <b-form-input  :state="!$v.contacto.correo.$invalid" type="email" v-model.trim="contacto.correo"></b-form-input>
                        <div style="color:red" v-if="!$v.contacto.correo.required">Este campo es obligatorio.</div>
                        <div style="color:red" v-if="!$v.contacto.correo.maxLength">El correo ingresado es muy largo.</div>
                        <div style="color:red" v-if="!$v.contacto.correo.email">Ingresa un correo válido.</div>
                    </b-col><br>
                </b-row>
                <b-row align-h="center" v-if="!isSendingData">
                    <b-col cols="11" md="9">
                        <br>
                        <label>Teléfono Móvil:</label>
                        <VuePhoneNumberInput v-model="contacto.telefono" :translations="{
                            countrySelectorLabel: 'Código país',
                            countrySelectorError: 'Elige un código de país',
                            phoneNumberLabel: '',
                            example: ''
                            }" 
                            default-country-code="MX"
                            @update="resultsPais = $event"
                            />
                        Enviaremos un SMS a este número para completar tu registro.
                        <div style="color:red" v-if="resultsPais!=null && !resultsPais.isValid">Ingresa un número de teléfono móvil.</div>
                    </b-col cols="11" md="9">
                </b-row>     
                <b-row align="center">
                    <b-col>
                        <br> 
                        <b-button v-if="!isSendingData" @click="sendData(1)" class="ht-btn ht-btn--outline ht-btn-default">REGISTRARME</b-button>
                        <b-spinner v-if="isSendingData" variant="secondary" ></b-spinner>
                        <h6 v-if="isSendingData">Espere por favor, estamos pocesando su registro ...</h6>
                        <br><div style="color:red">{{msgErrorRegistro}}</div>
                    </b-col>
                </b-row>     
            </div>
            <div v-if="registrado">
                <br>
                <h5>Hemos recibido tu registro</h5>
                <h6>En breve un asesor se pondrá en contacto contigo. Gracias!</h6>
            </div>
        </div>  
        <b-modal size="md" v-model="isShowModal" hide-footer>
            <div>
                <h6>Ingresa el código de verificación que hemos enviado al número que registraste.</h6><br>
                <b-form-input :disabled="isSendingData" type="text" v-model.trim="codigoVerificacion"></b-form-input>
                <br>
                <b-button v-if="!isSendingData" @click="validarCodigo()" class="ht-btn ht-btn--outline ht-btn-default">Válidar Código</b-button>
                <b-spinner v-if="isSendingData" variant="secondary" ></b-spinner>
                <h6 v-if="isSendingData">Espere por favor ...</h6>
                <br><div style="color:red">{{msgErrorRegistro}}</div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import Vue from 'vue'
    import Vuelidate from 'vuelidate'
    import { minLength, maxLength, required, email } from 'vuelidate/lib/validators';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import axios from 'axios'

    Vue.use(Vuelidate)
    Vue.component('vue-phone-number-input', VuePhoneNumberInput);
    export default {
        name: 'PricingOne',
        components: {
            VuePhoneNumberInput
        },
        data (){
            return {
                pricingTable: [
                    {
                        thumb: require('../assets/img/pricing/aeroland-branding-pricing-image-01.png'),
                        title: 'Estándar',
                        value: '289',
                        duration: 'mes',
                        alt: 'Pricing Thumb',
                        isPopular: false,
                        active: false,
                        price: ['1 Usuario',  '500 MB en la nube', 'Recepción de pagos en línea de trámites y servicios', 'Emisión de 10 facturas en línea', 'Generación automática de documentos hasta 1 plantilla', 'Soporte técnico']
                    },
                    {
                        thumb: require('../assets/img/pricing/aeroland-branding-pricing-image-02.png'),
                        title: 'Plus',
                        value: '599',
                        duration: 'mes',
                        alt: 'Pricing Thumb',
                        isPopular: false,
                        active: false,
                        price: ['3 Usuarios', '2GB en la nube', 'Recepción de pagos en línea de trámites y servicios', 'Emisión de 100 facturas en línea', 'Firma electrónica hasta 50 documentos', 'Generación automática de documentos hasta 3 plantillas', 'Soporte técnico']

                    },
                    {
                        thumb: require('../assets/img/pricing/aeroland-branding-pricing-image-03.png'),
                        title: 'Pro',
                        value: '1240',
                        duration: 'mes',
                        alt: 'Pricing Thumb',
                        isPopular: true,
                        active: true,
                        price: ['10 Uusarios','5 GB en la nube','Recepción de pagos en línea de trámites y servicios','Emisión de 500 facturas en línea','Firma electrónica hasta 100 documentos','Generación automática de documentos hasta 5 plantillas', 'Soporte técnico']
                    },
                    {
                        thumb: require('../assets/img/pricing/aeroland-branding-pricing-image-04.png'),
                        title: 'Premium',
                        value: '2090',
                        duration: 'mes',
                        alt: 'Pricing Thumb',
                        isPopular: false,
                        active: false,
                        price: ['20 Uusario','10 GB en la nube','Recepción de pagos en línea de trámites y servicios','Emisión de 1000 facturas en línea','Firma electrónica hasta 300 documentos','Generación automática de documentos hasta 10 plantillas','Soporte técnico']
                    }
                ],
                isShowModal:false,
                contacto:{
                    nombre:"",
                    tipoOrg:"",
                    correo:"",
                    telefono:""
                },
                optionsSector:[
                    "Gobierno",
                    "Educación",
                    "Salud",
                    "Retail",
                    "Tecnología",
                    "Religión",
                    "Organización Civil",
                    "Servicios",
                    "Otro"
                ],
                resultsPais:null,
                isSendingData:false,
                msgErrorRegistro:"",
                codigoVerificacion:"",
                codigoVerificacionServ:"",
                registrado : false
            }
        },
        methods:{
            stylePrice(title){
                if(title == 'Estándar'){
                    return 'text-decoration:line-through red;  '
                }
                return '';
            },
            limpiarFormulario(){
                this.contacto.obs="Número a validar: "+this.contacto.telefono,
                this.contacto.nombre="",
                this.contacto.tipoOrg="",
                this.contacto.correo="",
                this.contacto.telefono="";
                this.resultsPais="";
            },
            validarCodigo(){
                if(this.codigoVerificacion==null || this.codigoVerificacion==''){
                    this.msgErrorRegistro="Debe ingresar el código de verificación."
                    return "";
                }
                if(this.codigoVerificacion != this.codigoVerificacionServ){
                    this.msgErrorRegistro="El código de verificación es incorrecto."
                    return "";
                }
                this.sendData(2);
            },
            sendData(stage){
                if(stage==1 && (this.$v.$invalid || (this.resultsPais!=null && !this.resultsPais.isValid) ) ){
                    this.msgErrorRegistro="Aún faltan datos por llenar."
                    return "";
                }
                this.msgErrorRegistro="";
                this.isSendingData=true;
                axios({
                    method: 'post',
                    url: 'https://gobinet.appspot.com/registro',
                    params:{
                        "secuence":stage
                    },
                    data: {
                        "nombre": this.$v.contacto.nombre.$model,
                        "sector": this.$v.contacto.tipoOrg.$model,
                        "telefono": this.resultsPais.formattedNumber,
                        "correo": this.$v.contacto.correo.$model,
                        "obs": this.contacto.obs
                    }
                })
                .then(res => {                    
                    this.isSendingData=false;
                    if(stage==1){
                        this.isShowModal=true;
                        this.codigoVerificacionServ=res.data;                                        
                    }else{
                        this.isShowModal=false;
                        this.registrado=true;
                    }
                }).catch(err => {
                    this.isSendingData=false;
                    this.msgErrorRegistro=stage==1?"Hubo un error al enviar su registro. Intente nuevamente":"Hubo un error al válidar el código. Intente nuevamente";
                });
                this.limpiarFormulario();
            }
        },
        validations: {
        contacto:{
                nombre:{
                    minLength: minLength(5),
                    maxLength: maxLength(50),
                    required                    
                },
                tipoOrg:{
                    required
                },
                correo:{
                    required,
                    email,
                    maxLength: maxLength(50)
                }
            }
        }
    }
</script>