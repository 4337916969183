<template>
    <div class="saas-feature section-space--ptb_90 bg-gray" id="beneficios">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title text-center section-space--mb_60">
                        <h2 class="font-weight--light mb-15 wow move-up">Beneficios alcanzables para cada gobierno</h2>
                        <span class="section-text wow move-up">Rompe ahora la brecha digital y por fin lleva a tu gobierno al siguiente nivel</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div v-for="item in items" :key="item.id" class="col-lg-4 col-md-6 wow move-up">
                    <div class="service-grid text-center">
                        <div class="ht-service-box--one style-03">
                            <router-link :to="'branding'">
                                <div class="image__media" style="display: block; margin-left: auto; margin-right: auto;">
                                    <img :src="item.icon" class="img-fluid" alt="aeroland-app-showcase-box-icon-image-01">
                                </div>
                                <div class="content">
                                    <h6 class="heading">{{ item.heading }}</h6>
                                    <div class="service_text">{{ item.text }}</div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SassFeature',
        data (){
            return {
                items: [
                    {
                        icon: require('../assets/img/icons/aeroland-box-image-01.png'), 
                        heading: 'Aceptabilidad', 
                        text: 'Brinda mejores servicios y aumenta los indices de aceptación de la ciudadanía hacia el gobierno.'
                    },
                    {
                        icon: require('../assets/img/icons/aeroland-box-image-02.png'), 
                        heading: 'Certeza', 
                        text: 'Elimina la burocracia y los posibles actos de corrupción al crear un proceso ágil, seguro y transparente.'
                    },
                    {
                        icon: require('../assets/img/icons/aeroland-box-image-03.png'), 
                        heading: 'Gobierno Innovador', 
                        text: 'Cumple en materia de mejora regulatoria y reduce costos de operación hasta en un 90% en cada trámite.'
                    },
                    {
                        icon: require('../assets/img/icons/aeroland-box-image-04.png'), 
                        heading: 'Incremento de Recaudación', 
                        text: 'Incrementar y digitalizar los canales de pagos se traduce en un mayor potencial recaudatorio.'
                    },
                    {
                        icon: require('../assets/img/icons/aeroland-digital-store-sketch-icon.png'), 
                        heading: 'Disponibilidad 24/7', 
                        text: 'Aumenta el horario de atención reduciendole gastos a la ciudadanía y mejorando su calidad de vida.'
                    },
                    {
                        icon: require('../assets/img/icons/aeroland-box-image-06.png'), 
                        heading: 'Productividad Laboral', 
                        text: 'Crea un flujo de trabajo sin fricciones y mejora el tiempo de respuesta de cada trámite.'
                    },
                ]
            }
        }
    }
</script>