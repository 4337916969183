<template>
    <div class="tabs-wrapper section-space--pt_120 section-space--pb_60" :class="BgGradient">
        <div class="row">
            <div class="col-lg-8 col-md-10  ml-auto mr-auto">
                <div class="section-title-wrapper text-center section-space--mb_50 wow move-up">
                    <h3 class="section-title text-white">Creada para resolver las necesidades actuales de los Gobiernos Municipales</h3>
                </div>
            </div>
        </div>       
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <slick class="slick1 tab-slider-nav"
                        :options="slickOptions">
                            <div class="tab-slider-nav--list wow move-up">
                                <i class="ht-slider-tab-icon fal fa-align-left "></i>
                                <h5>Transformación Digital</h5>
                                <p>Digitaliza y optimiza el trabajo de las áreas administrativas, mejora la percepción del gobierno en la ciudadanía y aumenta los índices de recaudación al brindar más y mejores servicios de manera transparente.</p>
                                <p>GobiNet esta desarollada en estricto apego al marco normativo del Gobierno Digital, Ley de Ingresos, Contabilidad Gubernamental, Mejora Regulatoria, Protección de Datos Personales y Firma Electrónica Avanzada.</p>
                            </div>
                            <!--
                            <div class="tab-slider-nav--list wow move-up">
                                <i class="ht-slider-tab-icon fal fa-align-left"></i>
                                <h5>Servicios Escolares</h5>
                                <p>Digitaliza el proceso de inscripción y reinscripción de alumnos, recibe el pago de colegiaturas en línea, firma digitalmente cualquier documento escolar y envía por correo electrónico las constancias y boletas escolares de cada periodo.</p>
                            </div>
                            <!---
                            <div class="tab-slider-nav--list wow move-up">
                                <i class="ht-slider-tab-icon fal fa-rocket"></i>
                                <h5>Club de Membresias</h5>
                                <p>Inscripción y recepción del pago de clientes en gimnasios, clínicas o alquileres</p>
                            </div>-->
                    </slick>
                </div>
                <div class="col-lg-8">
                    <slick class="slick2"
                        :options="slickOptions2">
                            <div class="pro-large-img img-zoom wow move-up">
                                <img class="img-fluid" src="../assets/img/banners/gobierno_pad.png" alt="product-details" />
                            </div>
                            <!---
                            <div class="pro-large-img img-zoom wow move-up">
                                <img class="img-fluid" src="../assets/img/banners/escolares_pad.png" alt="product-details" />
                            </div>
                            <!--
                            <div class="pro-large-img img-zoom wow move-up">
                                <img class="img-fluid" src="../assets/img/banners/recursos_pad.png" alt="product-details" />
                            </div>
                            -->
                    </slick>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Slick from 'vue-slick';

    export default {
        name: 'TabTwo',
        props: ["BgGradient"],
        components: {
            Slick
        },
        data() {
            return {
                slickOptions: {
                    slidesToShow: 2,
                    focusOnSelect: true,
                    vertical: true,
                    asNavFor: '.slick2'
                },
                slickOptions2: {
                    arrows: false,
                    fade:true,
                    dots:true,
                    asNavFor: '.slick1'
                }
            };
        }
    };
</script>