<template>
    <div class="newsletter-section section-space--ptb_120 default-bg" id="proceso">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-10  ml-auto mr-auto">
                    <div class="section-title-wrapper text-center section-space--mb_50 wow move-up">
                        <h3 class="section-title text-white">¿Cómo funciona?</h3>
                    </div>
                </div>
            </div>
            <Gradation/>
        </div>
    </div>
</template>

<script>
    import Gradation from '../components/Gradation'

    export default {
        name: 'newsletter',
        components: {
            Gradation
        }
    }
</script>