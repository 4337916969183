<template>
    <div class="icon-process-wrapper">
        <div class="icon-process-single wow move-up">
            <div class="icon-process-single__line">
                <div class="dot dot-1"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-5"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-1"></div>
            </div>
            <div class="icon-process-single__content-wrap">
                <div class="icon-process-single__media">
                    <i class="fa fa-folder-open"></i>
                </div>
                <h5 class="proces-title">Envío de Requisitos</h5>
                <div class="proces-text">
                    Recibe en línea documentos pdf, audios, videos o cualquier otro archivo que tu institución solicite como parte de un trámite o proceso administrativo.
                </div>
            </div>
        </div>
        <div class="icon-process-single wow move-up">
            <div class="icon-process-single__line">
                <div class="dot dot-1"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-5"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-1"></div>
            </div>
            <div class="icon-process-single__content-wrap">
                <div class="icon-process-single__media">
                    <i class="fa fa-credit-card"></i>
                </div>
                <h5 class="proces-title">Recepción de pagos</h5>
                <div class="proces-text">
                    Ofrece a tus clientes la emisión de facturas y el pago en línea de trámites y servicios con cargo a tarjeta o emite líneas de captura para el pago en bancos y tiendas.
                </div>
            </div>
        </div>
        <div class="icon-process-single wow move-up">
            <div class="icon-process-single__line">
                <div class="dot dot-1"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-5"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-1"></div>
            </div>
            <div class="icon-process-single__content-wrap">
                <div class="icon-process-single__media">
                    <i class="fa fa-file"></i>
                </div>
                <h5 class="proces-title">Firma Digital</h5>
                <div class="proces-text">
                    Firma digitalmente los documentos a entregar de cada trámite usando tu certificado del SAT y envíalos automáticamente desde la plataforma.
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    export default{
        name: 'Gradation',
    };
</script>
<style>
.logo-img path {
  fill: #FFFFFF;
}
.proces-title{
    color:#FFFFFF;
    margin-bottom:12px;
}
.proces-text{
    color:#FFFFFF;
}
</style>
