<template>
    <div class="row">
        <div v-for="ebook in ebooks" :key="ebook.id" class="col-lg-4 col-md-6 wow move-up">
            <div class="service-grid" >
                <div class="ht-service-box--two text-center" style="margin-left: 4px;">
                    <router-link :to="'branding'">
                        <div class="image mb-20">
                            <img :src="ebook.icon" style="max-width:200px; max-height:100px;" class="img-fluid" :alt="ebook.alt">
                        </div>
                        <div class="content">
                            <h6 class="sub-heading">{{ ebook.subheading }}</h6>
                            <h6 class="ht-heading mb-15">{{ ebook.heading }}</h6>
                            <div class="service_text">{{ ebook.text }}</div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'EbookFeature',
        data (){
            return {
                ebooks: [
                    {
                        icon: require('../assets/img/icons/aeroland-ebook-box-image-02.png'), 
                        alt:'aeroland-ebook-box-image-02', 
                        heading:'Personalizable', 
                        subheading:'Plataforma', 
                        text:'Personalización de formularios para el envío de requisitos, tarifas de cobro y plantillas para la emisión automática de documentos.'
                    },
                    {
                        icon: require('../assets/img/icons/cobro-automatico.svg'),
                        alt:'aeroland-ebook-box-image-01', 
                        heading:'Automatizada', 
                        subheading:'Cobranza', 
                        text:'Aplicación automática de actualizaciones, recargos, multas y bonificaciones establecidas en el marco fiscal de cada institución.'
                    },
                    {
                        icon: require('../assets/img/icons/aeroland-ebook-box-image-01.png'), 
                        alt:'aeroland-ebook-box-image-03', 
                        heading:'Servicios', 
                        subheading:'Portal de', 
                        text:'Página web personalizada de tu institución para la generación automática de líneas de captura y factura electrónica de clientes.'
                    },
                    {
                        icon: require('../assets/img/icons/writing.svg'), 
                        alt:'aeroland-ebook-box-image-04', 
                        heading:'Firma Electrónica', 
                        subheading:'Servicio de', 
                        text:'Firmado Electrónico de documentos haciendo uso de tu certificado emitido por el SAT y generación de la constancia de conservación NOM-151.'
                    },
                    {
                        icon: require('../assets/img/icons/padrones2.svg'), 
                        alt:'aeroland-ebook-box-image-06', 
                        heading:'Clientes', 
                        subheading:'Control de pagos de', 
                        text:'Administración del histórico de pagos de cada cliente, configuración de pagos recurrentes y generación de reportes contables.'
                    },
                    {
                        icon: require('../assets/img/icons/banco3.svg'), 
                        alt:'aeroland-ebook-box-image-06', 
                        heading:'Pagos En Línea', 
                        subheading:'Recepción de', 
                        text:'Implementación del pago en línea directamente con tu banco preferente sin intermediarios, recibe el dinero al instante y directo en tu cuenta bancaria.'
                    },
                ]
            }
        }
    }
</script>