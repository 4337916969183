import Index from '../pages/Index'

export const routes = [
    {
        path:'/index',
        name: 'Index',
        component: Index
    },
    {
        path:'/',
        name: 'Index',
        component: Index
    },
    {
        path:'/index.html',
        name: 'Index',
        component: Index
    }
];