<template>
    <div class="main-container">

        <!--<router-view></router-view>-->
        <!-- Navbar section -->
        <HeaderAppLanding />

        <!-- encabezado -->
        <HeroAppLanding />

        
        <div class="pricing-table-area bg-gray-2 section-space--pb_90" id="descripcion">
            <div class="pricing-table-title-area position-relative ">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12  wow move-up">
                            <div class="section-title-wrapper text-center section-space--mb_60">
                                <br><br>
                                <h2 class="section-title font-weight--light mb-15 wow move-up">¿Aún no conoces GobiNet?</h2>
                                <span class="section-text wow move-up">¿Estás pensando en digitalizar tus trámites y pagos de servicios para optimizar tu flujo de trabajo? Te presentamos la forma mas rápida y segura de hacerlo.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pricing-table-content-area">
                <div >
                    <VideoIntro />
                </div>
            </div>
        </div>

        
        <!-- ventajas -->
        <div class="tabs-wrapper section-space--mt_30 section-space--pb_120" id="ventajas"><!--startup-tab-->
            <div class="container">                
                <!-- tab components -->
               <Ventajas />
            </div>
        </div>

        <!-- tab two components -->
        <TabTwo BgGradient="bg-gradient" id="casosuso" />

        <!-- beneficios -->
		<!--<Beneficios class="gray-gradient"/>-->
        <!-- testimonial section -->
		<div class="testimonial-slider-area section-space--ptb_120 bg-gray" id="beneficios">
            <div class="container">
				<div class="section-title text-center section-space--mb_60">
					<h2 class="section-title font-weight--light wow move-up">Beneficios alcanzables</h2>
				</div>
                <TestimonialTwo />  
            </div>
        </div>

        <!-- proceso -->
        <Proceso></Proceso>


        <!-- caracteristicas -->
        <div class="ebook-feature section-space--pt_120 section-space--pb_60" id="caracteristicas">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title-wrapper text-center section-space--mb_60">
                            <h6 class="section-sub-title mb-20 font-weight--bold wow move-up">¿Qué incluye?</h6>
                            <h2 class="section-title font-weight--light wow move-up">Desarrollada para cumplir las necesidades<br> específicas de cada institución</h2>
                        </div>
                    </div>
                </div>
                <!-- caracteriscticas component -->
                <Caracteristicas />
            </div>
        </div>

        <!-- facts section -->
        <div class="fun-fact-wrapper section-space--pb_120">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title text-center section-space--mb_60">
                            <h2 class="font-weight--light mb-15 move-up">Sistema de Recaudación SIPREM®</h2>
                            <span class="section-text wow move-up">GobiNet es una plataforma ligada al motor de pagos SIPREM®, lanzado en el 2015 y a la fecha con una disponibilidad de servicio 24/7</span>
                        </div>
                    </div>
                </div>
                <!-- fact component -->
                <FunFactOne/>
            </div>
        </div>
        
        <!-- pricing section -->
        <CurveShape>
            <template v-slot:bgcolor>
                <path style="fill: #f6f5f9;
                    stroke-miterlimit:10;" 
                    d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                        S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                        S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z"/>
            </template>
        </CurveShape>
        <div class="pricing-table-area bg-gray-2 section-space--pb_90" id="precios">
            <div class="pricing-table-title-area position-relative ">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12  wow move-up">
                            <div class="section-title-wrapper text-center section-space--mb_60">
                                <br><br>
                                <h2 class="section-title font-weight--light mb-15 wow move-up">Ahora disponible para todos !</h2>
                                <span class="section-text wow move-up">Plataforma sin cargos ocultos ni plazos forzosos, tu decides cuando terminar.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pricing-table-content-area">
                <div class="container">
                    <!-- pricing component -->
                    <PricingOne />
                </div>                
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12  wow move-up">
                        <div class="section-title-wrapper text-center section-space--mb_60 ">
                            <h4 class="section-title font-weight--light mb-15 wow move-up" >¿No encontraste un plan adecuado para ti?</h4>
                            <h5 class="section-title font-weight--light mb-15 wow move-up">No te preocupes, contáctanos para ofrecerte una solución a tu medida.</h5><br>
                            <b-button class="btn btn--green" target="_blank" :href="sendMsgWhatsApp">Solicitar Cotización</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- preguntas frecuentes -->
        <div class="software-accordion-wrapper section-space--pt_120 section-space--pb_60" id="preguntas"><!--gray-gradient-->
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title-wrapper text-center section-space--mb_60">
                            <h2 class="section-title font-weight--light mb-10 wow move-up">Preguntas Frecuentes</h2>
                            <span class="section-text wow move-up">Es un gusto para nosotros resolver todas tus dudas.</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6"><!--../assets/img/features/aeroland-software-image-06.png-->
                        <img src="../assets/img/banners/aeroland-tab-content-image-04.png" alt="" class="img-fluid">
                    </div>
                    <div class="col-lg-6">
                        <div class="faq-wrapper soft-accordion-two">
                            <!-- accordion component -->
                            <Accordion />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- newsletter section -->
        <Newsletter /> 

        <!-- footer section -->
        <FooterMain />

        <!-- offcanvas search component -->
        <OffcanvasSearchBox />
    </div>
</template>

<script>
    import TestimonialTwo from '../components/TestimonialTwo'
    import HeaderAppLanding from '../components/HeaderAppLanding'
    import HeroAppLanding from '../components/HeroAppLanding'
    import CurveShape from '../components/CurveShape'
    import Ventajas from '../components/Ventajas'
    import Proceso from '../components/Proceso'
    import Beneficios from '../components/Beneficios'
    import Caracteristicas from '../components/Caracteristicas'
    import FunFactOne from '../components/FunFactOne'
    import Accordion from '../components/Accordion'
    import FooterMain from '../components/FooterMain'
    import PricingOne from '../components/PricingOne'
    import VideoIntro from '../components/VideoIntro'
    import TabTwo from '../components/TabTwo'
    import Newsletter from '../components/Newsletter'
    import OffcanvasSearchBox from '../components/OffcanvasSearchBox'

    export default {
        name: 'app',
        data () {
            return {
                bgImg: {
                    backgroundImage: `url(${require('../assets/img/bg/aeroland-sass-image-04.jpg')})`
                },
                sendMsgWhatsApp:"https://api.whatsapp.com/send?phone=+527299645819&text=Hola, necesito obtener un plan personalizado para mi institución..",
            }
        },
        components: {
            TestimonialTwo,
            HeaderAppLanding,
            HeroAppLanding,
            CurveShape,
            Ventajas,
            Proceso,
            Beneficios,
            Caracteristicas,
            FunFactOne,
            Accordion,
            FooterMain,
            PricingOne,
            VideoIntro,
            TabTwo,
            Newsletter,
            OffcanvasSearchBox
        }
    }
</script>
<style>
#show_bg_2 {
    background-image:
    linear-gradient(to bottom, rgba(99, 30, 155, 0.7), rgba(99, 30, 155, 0.6)),
    url('../assets/img/features/bussines.jpg');
    width: 100%;
    height: 500px;
    background-size: cover;
    color: white;
    padding: 20px;
    text-align:center;
    font-size: 30px;
    font-weight: bold;

}
</style>