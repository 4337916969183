<template>
    <div class="newsletter-section section-space--ptb_120 default-bg" id="contacto">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-10  ml-auto mr-auto">
                    <div class="section-title-wrapper text-center section-space--mb_50 wow move-up">
                        <h3 class="section-title text-white">¿Necesitas más información?<br> no dudes en platicar con nosotros!</h3>
                    </div>
                    <!-- newsletter form -->
                    <div class="newsletter-form--one section-space--mb_20 text-center wow move-up">
                        <form action="#">
                            <b-button class="btn btn--green" target="_blank" :href='sendMsgWhatsApp'>Enviar Whatsapp</b-button><br><br>
                    </form>
                    </div>
                    <!-- newsletter text -->
                    <h6 class="text-white text-center wow move-up">Quieres agendar una cita? <a @click="isShowModal=true" class="hover-style-link hover-style-link--green">Registrate</a></h6>
                </div>
            </div>
        </div>
        <!------------- MODAL ------------------>
        <b-modal   v-model="isShowModal" size="md" hide-footer>
            <Registro></Registro>
        </b-modal>
    </div>
</template>

<script>
    import Registro from '../components/Registro'
    export default {
        name: 'newsletter',
        components:{
            Registro
        },
        data(){
            return{
                sendMsgWhatsApp:"https://api.whatsapp.com/send?phone=+527299645819&text=Hola, me gustaría recibir más información acerca de la plataforma.",
                sendMsgWhatsApp2:"https://api.whatsapp.com/send?phone=+527299645819&text=Hola, me gustaría agendar una cita.",
                isShowModal:false

            }

        }
    }
</script>