<template>
    <div class="accordion-faq wow move-up">
        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-1 class="faq-title">Contratación</b-button>
            </b-card-header>
            <b-collapse id="accordion-1" visible accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                        Para hacer uso de la plataforma debes seguir los siguientes pasos:<br>
                        1.- Elegir el tipo de plan a contratar en el apartado de <b>Precios</b> y dar click en <b>Obtener</b>.<br>
                        2.- Llena el formulario de registro para obtener tu usuario y contraseña para el acceso a la plataforma.<br>
                        3.- Un asesor podrá ponerse en contacto contigo para ayudarte a realizar la configuración necesaria de la plataforma para su uso.<br>
                        4.- Una vez que tu cuenta este configurada completamente y en caso de haber escogido un plan con costo, debes realizar el pago correspondiente.<br><br>
                        Nota:Puedes cancelar tu suscripción en cualquier momento sin penalizaciones.
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>

        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-2 class="faq-title">Tiempo de Implementación</b-button>
            </b-card-header>
            <b-collapse id="accordion-2" accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                        Actualmente GobiNet ya se encuentra en funcionamiento y listo para empezar a recibir tus trámites y pagos en línea. <br> El tiempo de configuración para cada institución es de 1 día.
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>

        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-3 class="faq-title">Capacitación</b-button>
            </b-card-header>
            <b-collapse id="accordion-3" accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                        Brindamos capacitación técnica y jurídica para el uso de la plataforma a los trabajadores que estarán en contacto con ella.
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>

        <b-card no-body>
            <b-card-header header-tag="header">
                <b-button block v-b-toggle.accordion-4 class="faq-title">Legalidad</b-button>
            </b-card-header>
            <b-collapse id="accordion-4" accordion="my-accordion">
                <b-card-body>
                    <b-card-text>
                        Para la firma electrónica de documentos usamos la FIEL emitida por el SAT y cada documento firmado con la plataforma cuenta con la constancia de conservación NOM-151 emitida por un PSC, lo cual permite al documento ser utilizado como medio de prueba legal gracias a la Ley de Firma Electrónica Avanzada en México.
                    </b-card-text>
                </b-card-body>
            </b-collapse>
        </b-card>

    </div>
</template>

<script>
    export default {
        name: 'Accordion',
    };
</script>