<template>
    <div class="footer-area-wrapper" :class="FooterStyle">
        <div class="footer-area section-space--ptb_90">
            <div class="container">
                <div class="row footer-widget-wrapper">
                    <div class="col-lg-4 col-md-6 footer-widget">
                        <div class="footer-widget__logo mb-30">
                            <router-link to="/">
                                <img src="../assets/img/logo/logo-dark.png" style="width:200px" class="img-fluid logo-dark" alt="">
                                <img src="../assets/img/logo/logo-light.png" style="width:200px" class="img-fluid logo-light" alt="">
                            </router-link>
                        </div>
                        <ul class="footer-widget__list">
                            <li><a  class="hover-style-link">info@gobinet.mx</a></li>
                            <li><a  href="tel:5530802644" class="hover-style-link">tel. <b>729 964 5819</b></a></li>
                            <li><a  class="hover-style-link hover-style-link--green">www.gobinet.mx</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-md-6 footer-widget">
                        <h6 class="footer-widget__title mb-20">Qué es GobiNet?</h6>
                        <ul class="footer-widget__list">
                            <li><a href="#home" class="hover-style-link">Inicio</a></li>
                            <li><a href="#casosuso" class="hover-style-link">Casos de uso</a></li>
                            <li><a href="#beneficios" class="hover-style-link">Beneficios</a></li>
                            <li><a href="#caracteristicas" class="hover-style-link">Características</a></li>
                            <li><a href="#precios" class="hover-style-link">Precios</a></li>
                            <li><a href="#preguntas" class="hover-style-link">Preguntas frecuentes</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-md-6 footer-widget">
                        <h6 class="footer-widget__title mb-20">Normatividad</h6>
                        <ul class="footer-widget__list">
                            <li><a href="https://gobinet.mx/terminos_y_condiciones.pdf" target="_blank" class="hover-style-link">Términos del servicio</a></li>
                            <li><a href="https://gobinet.mx/aviso_de_privacidad.pdf" target="_blank" class="hover-style-link">Aviso de privacidad</a></li>
                            
                        </ul>
                    </div>
                    
                    <div class="col-lg-3 col-md-6 offset-lg-1 footer-widget">
                        <div class="twitter-feed-slider">
                            <div class="col-md-6 text-center text-md-left">
                                <ul class="list ht-social-networks solid-rounded-icon">
                                    <li class="item">
                                        <a href="https://www.facebook.com/gobinetmx" target="_blank" class="social-link"> <i class="fab fa-facebook social-link-icon"></i> </a>
                                    </li>
                                    <li class="item">
                                        <a href="https://www.facebook.com/gobinetmx" target="_blank" class="social-link"> <i class="fab fa-twitter social-link-icon"></i> </a>
                                    </li>
                                    <li class="item">
                                        <a href="https://www.youtube.com/watch?v=OzNLA3lw-sI&list=PLAJs4qochsfSYXwJG7fnlxDA4zWZ3tkLf" target="_blank" class="social-link"> <i class="fab fa-youtube social-link-icon"></i> </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-copyright-area border-top section-space--ptb_30">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 text-center text-md-left">
                        <span class="copyright-text">© 2023 Todos los Derechos Reservados.</span>
                    </div>
                </div>
            </div>
        </div>
        <b-modal>
            {{}}
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'FooterMain',
        props: ["FooterStyle"],
        data (){
            return{
            }
        }
    }
</script>