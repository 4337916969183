<template>
    <div class="tab-history-wrap section-space--mt_80">
        <div class="row">
            <div class="col-lg-5">
                <div class="tab-content-inner">
                    <h4 class="section-title font-weight--light wow move-up">Plataforma Rápida, Fácil y Segura</h4><br>
                    <ul class="check-list green-check-list section-space--mb_20">
                        <li class="list-item">Digitaliza tus trámites, procesos administrativos y de cobranza en cuestión de minutos.</li>
                        <li class="list-item">Ofrece pagos en línea desde tu página web y recíbelos en tu cuenta bancaria sin intermediarios.</li>
                        <li class="list-item">Genera documentos digitales con validez legal usando tu Firma Electrónica del SAT.</li>
                    </ul>
                    <div >
                        <img src="../assets/img/logo/google-cloud.png" width="350"  class="img-fluid">
                    </div><br>
                    <div class="tab-button mb-30">
                        <a @click="isShowModal=true" class="ht-btn ht-btn-md">Solicita una cuenta gratis </a>
                    </div>
                    <h6 class="sub-heading">Tienes alguna duda? <a href="#contacto" class="hover-style-link hover-style-link--green">Conoce más aquí</a></h6>
                    <br>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="tab-history-image">
                    <img src="../assets/img/hero/hero-software/software-thumb-1.png" width="600" height="470" class="img-fluid">
                </div>
            </div>
        </div>
        <!------------- MODAL ------------------>
        <b-modal   v-model="isShowModal" size="md" hide-footer>
            <Registro></Registro>
        </b-modal>
    </div>
</template>

<script>
    import Registro from '../components/Registro'
    export default {
        name: 'TabContentOne',
        components:{
            Registro
        },
        data(){
            return {
                isShowModal:false,
            }

        }
    };
</script>