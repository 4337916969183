<template>
    <div class="curve-shape">
        <svg  
            x="0px"
            y="0px" 
            viewBox="0 -14 3841 120"
            style="overflow:scroll;
            enable-background:new 0 -14 3841 120;" 
            xml:space="preserve">
            <slot name="bgcolor"></slot>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'CurveShape',
    };
</script>

<style lang="scss" scoped>
    .curve-shape {        
        overflow-x: hidden;
        svg {
            width: 200%;
            position: relative;
            animation: move-left 3s linear infinite;
        } ;
    }
        
    @keyframes move-left {
        0%{
            left: 0%;
        }
        100%{
            left: -100%;
        }     
    }
</style>