<template>
    <b-navbar toggleable="xl" fixed="top" v-b-scrollspy:nav-scroller class="header-area text-white" :class="{'is-sticky': scrolled}">
        <div class="container-fluid container-fluid--cp-150">
            
            <b-navbar-brand class="navbar-brand logo-light" ><!--to="/branding"-->
                <img :src='"../assets/img/logo/gobinetlogo.png"' style="width:130px;"  >
            </b-navbar-brand>
            <b-navbar-brand class="navbar-brand logo-dark"><!--to="/branding"-->
                <img :src='"../assets/img/logo/gobinetlogocolor.png"' style="width:130px;" >
            </b-navbar-brand>
            
            <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
            <b-collapse class="default-nav justify-content-center"  is-nav id="nav_collapse">
                <b-navbar-nav class="navbar-nav main-menu text-white">
                    <b-nav-item href="#casosuso" class="scroll"><span>Casos de Uso</span></b-nav-item>
                    <b-nav-item href="#beneficios" class="scroll"><span>Beneficios</span></b-nav-item>                   
                    <b-nav-item href="#proceso" class="scroll"><span>Características</span></b-nav-item>
                    <b-nav-item href="#precios" class="scroll"><span>Precios</span></b-nav-item>
                    <b-nav-item href="#contacto" class="scroll"><span>Contacto</span></b-nav-item>
                    <b-nav-item>
                        <b-btn-group class="header-button button--white d-block d-xl-none">
                            <b-link class="btn btn--white" @click="isShowModal=true">Obtener cuenta gratis</b-link>
                        </b-btn-group>
                    </b-nav-item>
                </b-navbar-nav>
                
            </b-collapse>

            <b-btn-group class="header-button button--white d-none d-xl-block">
                <b-link class="btn btn--white" @click="isShowModal=true">Obtener cuenta gratis</b-link>
            </b-btn-group>
            
        </div>
        
        <!------------- MODAL ------------------>
        <b-modal   v-model="isShowModal" size="md" hide-footer>
            <Registro></Registro>
        </b-modal>
    </b-navbar>
</template>

<script>
    import Registro from '../components/Registro'
    export default {
        name:'HeaderAppLanding',
        data (){
            return {
                load: false,
                limitPosition: 200,
                scrolled: false,
                lastPosition: 500,
                isShowModal:false
            }
        },
        components:{
            Registro
        },
        mounted (){
            (function() {
                scrollTo();
            })();

            function scrollTo() {
                const links = document.querySelectorAll('.scroll > a');
                links.forEach(each => (each.onclick = scrollAnchors));
            }

            function scrollAnchors(e, respond = null) {
                const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);
                e.preventDefault();
                var targetID = (respond) ? respond.getAttribute('href') : this.getAttribute('href');
                const targetAnchor = document.querySelector(targetID);
                if (!targetAnchor) return;
                const originalTop = distanceToTop(targetAnchor);
                window.scrollBy({ top: originalTop, left: 0, behavior: 'smooth' });
                const checkIfDone = setInterval(function() {
                    const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 190;
                    if (distanceToTop(targetAnchor) === 0 || atBottom) {
                        targetAnchor.tabIndex = '-1';
                        targetAnchor.focus();
                        //window.history.pushState('', '', targetID);
                        clearInterval(checkIfDone);
                    }
                }, 800);
            }
        },
        methods: {
            // sticky menu script
            handleScroll() {
                if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
                    this.scrolled = true;
                    // move up!
                } 
                
                if (this.lastPosition > window.scrollY) {
                    this.scrolled = true;
                    // move down
                }
                
                this.lastPosition = window.scrollY;
                this.scrolled = window.scrollY > 50;
            }
        }, 
        created() {
            window.addEventListener("scroll", this.handleScroll);
        },
        destroyed() {
            window.removeEventListener("scroll", this.handleScroll);
        },
    }
</script>